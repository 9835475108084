<template>
  <div>
    <title-header :title="title" :buttons="[]"></title-header>
    <v-container class="ml-8">
      <v-row>
        <v-col cols="12" md="5" sm="8">
          <v-text-field outlined label="*Nombre" v-model="rol.name" ></v-text-field>
        </v-col>
        <v-col cols="7" md="7" sm="8">
          <v-text-field outlined label="*Descripción" v-model="rol.description" ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="modules"
        :items-per-page="15"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:item.view="{ item }">
          <div v-for="permission in item.permissions" :key="permission.id">
            <v-checkbox
              v-if="permission.slug === 'view' && permissionsChecks[item.slug]"
              color="primary"
              v-model="permissionsChecks[item.slug].view"
              @change="changePermission($event, permission, 'view')"
            ></v-checkbox>
          </div>
        </template>
        <template v-slot:item.create="{ item }">
          <div v-for="permission in item.permissions" :key="permission.id">
            <v-checkbox
              v-if="permission.slug === 'create' && permissionsChecks[item.slug]"
              color="primary"
              v-model="permissionsChecks[item.slug].create"
              @change="changePermission($event, permission, 'create')"
            ></v-checkbox>
          </div>
        </template>
        <template v-slot:item.edit="{ item }">
          <div v-for="permission in item.permissions" :key="permission.id">
            <v-checkbox
              v-if="permission.slug === 'edit' && permissionsChecks[item.slug]"
              color="primary"
              v-model="permissionsChecks[item.slug].edit"
              @change="changePermission($event, permission, 'edit')"
            ></v-checkbox>
          </div>
        </template>
        <template v-slot:item.delete="{ item }">
          <div v-for="permission in item.permissions" :key="permission.id">
            <v-checkbox
              v-if="permission.slug === 'delete' && permissionsChecks[item.slug]"
              color="primary"
              v-model="permissionsChecks[item.slug].delete"
              @change="changePermission($event, permission, 'delete')"
            ></v-checkbox>
          </div>
        </template>
      </v-data-table>

      <v-divider width="80%"></v-divider>
      <div class="mt-5 mb-20">
        <v-btn class="mr-6" rounded outlined color="primary" width="10rem" dark @click="cancel()">Cancelar</v-btn>
        <v-btn
          rounded
          color="primary"
          width="10rem"
          @click="updateClicked()"
        >Actualizar</v-btn>
      </div>
    </v-container>
    <v-dialog v-model="dialog" persistent max-width="480">
      <v-card>
      <v-card-title class="headline">Felicidades</v-card-title>
      <v-card-text>El rol se ha actualizado satisfactoriamente.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="cancel()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar color="primary" v-model="showSnackbar">
      {{snackBarText}}
      <v-btn text @click="showSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import TitleHeader from '@/components/app/title-header/title-header'
import { ROLES_GET_MODULES, ROLES_UPDATE_ROLE } from '../../../store/actions.type'
import { ROLES_SELECT_ROL } from '../../../store/mutations.type'

export default {
  name: 'roles-edit',
  props: {},
  data() {
    return {
      title: 'Editar Rol',
      snackBarText: '',
      showSnackbar: false,
      dialog: false,
      permissionsChecks: {
        issues: {
          view: false,
          edit: false,
          create: false
        },
        tracking: {
          view: false
        },
        places: {
          view: false,
          edit: false,
          create: false,
          delete: false
        },
        'settings/organization': {
          view: false,
          edit: false
        },
        'settings/application': {
          view: false,
          edit: false
        },
        'settings/groups': {
          view: false,
          edit: false,
          create: false,
          delete: false
        },
        'settings/roles': {
          view: false,
          edit: false,
          create: false,
          delete: false
        },
        'settings/issue-types': {
          view: false,
          edit: false,
          create: false,
          delete: false
        },
        'settings/issue-status': {
          view: false,
          edit: false,
          create: false,
          delete: false
        },
        'settings/fields': {
          view: false,
          edit: false,
          create: false,
          delete: false
        },
        'settings/forms': {
          view: false,
          edit: false,
          create: false,
          delete: false
        },
        'settings/workflows': {
          view: false,
          edit: false,
          create: false,
          delete: false
        },
        'settings/users': {
          view: false,
          edit: false,
          create: false,
          delete: false
        }
      },
      permissions: [],
      headers: [
        { text: 'Modulos', value: 'name', sortable: false },
        { text: 'Ver', value: 'view', sortable: false },
        { text: 'Crear', value: 'create', sortable: false },
        { text: 'Editar', value: 'edit', sortable: false },
        { text: 'Eliminar', value: 'delete', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters({
      rol: 'getSelectedRol',
      modules: 'getModules'
    })
  },
  methods: {
    ...mapMutations({
      selectRol: ROLES_SELECT_ROL
    }),
    ...mapActions({
      getModules: ROLES_GET_MODULES,
      updateRole: ROLES_UPDATE_ROLE
    }),
    cancel() {
      this.$router.push('/settings/roles')
    },
    changePermission(e, permission, slug) {
      const hasPermissionRol = this.permissions.find(p => p.slug === slug && permission.moduleId === p.moduleId && permission.id === p.id)
      if (hasPermissionRol) {
        const index = this.permissions.findIndex(p => p.slug === slug && permission.moduleId === p.moduleId && permission.id === p.id)
        this.permissions.splice(index, 1)
      } else {
        // if (slug === 'edit' || slug === 'create' || slug === 'delete') {
        //   const viewPermission = this.modules.find(m => {
        //     if (m.id === permission.moduleId) {
        //       this.permissionsChecks[m.slug].view = true
        //       return m.permissions.find(p => p.slug === 'view')
        //     }
        //   })
        //   this.permissions.push(viewPermission)
        // }
        this.permissions.push(permission)
      }
    },
    updateClicked() {
      this.rol.permissions = this.permissions
      this.updateRole(this.rol)
        .then(response => {
          this.dialog = true
        })
        .catch(e => {
          this.snackBarText = e.message
          this.showSnackbar = true
        })
    }
  },
  async created() {
    this.selectRol(this.$route.params.id)
    await this.getModules()
    this.permissions = this.rol.permissions
    this.permissions.forEach((p) => {
      const module = this.modules.find(m => m.id === p.moduleId)
      if (this.permissionsChecks[module.slug]) {
        switch (p.slug) {
        case 'view':
          this.permissionsChecks[module.slug].view = true
          break
        case 'create':
          this.permissionsChecks[module.slug].create = true
          break
        case 'edit':
          this.permissionsChecks[module.slug].edit = true
          break
        case 'delete':
          this.permissionsChecks[module.slug].delete = true
          break
        }
      }
    })
  },
  components: {
    TitleHeader
  }
}
</script>
